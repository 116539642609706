import { Typography } from "@mui/material";
import { useFormikContext, Formik } from "formik";

export const reduceObj = (obj, key) => {
  const keys = key.split(".");
  let temp = obj[keys[0]];
  for (let i = 1; i < keys.length; ++i) {
    if (temp) {
      temp = temp[keys[i]];
    } else return undefined;
  }
  return temp;
};

const Radio = ({ name, label, handleChange, required = false }) => {
  const { setFieldValue, values, errors, touched, submitCount } =
    useFormikContext();

  const handleRadioChange = (value) => {
    setFieldValue(name, value);
    if (handleChange) {
      handleChange(value);
    }
  };

  return (
    <>
      <div className="radio-wrapper">
        <Typography variant="body1" fontSize="16px">
          {label}
          {required && (
            <span style={{ color: "red", fontWeight: "bold" }}> *</span>
          )}
        </Typography>

        <div className="radio-container">
          <input
            type="radio"
            id={name.replaceAll(".", "") + "Yes"}
            name={name}
            value={"Y"}
            checked={reduceObj(values, name) === "Y"}
            onChange={(e) => {
              setFieldValue(name, "Y");
              handleRadioChange("Y");
            }}
          />
          <label htmlFor={name.replaceAll(".", "") + "Yes"}>Yes</label>

          <input
            type="radio"
            id={name.replaceAll(".", "") + "No"}
            name={name}
            value={"N"}
            checked={reduceObj(values, name) === "N"}
            onChange={(e) => {
              setFieldValue(name, "N");
              handleRadioChange("N");
            }}
          />
          <label htmlFor={name.replaceAll(".", "") + "No"}>No</label>
        </div>

        {/* Show validation error if no option is selected */}
        {reduceObj(errors, name) && submitCount > 0 && (
          <div style={{ color: "red", fontSize: "14px" }}>
            {reduceObj(errors, name)}
          </div>
        )}
      </div>
    </>
  );
};

export default Radio;
