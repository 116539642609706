import { Box, Button, colors, FormHelperText, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import MemberOption from "../../../components/MemberOption/MemberOption";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import {
  MembersInterface,
  setActiveStep,
  setGender,
  setMemberDetails,
} from "../../../modules/landing.slice";
import logo from "../../../assets/images/logo.png";

const MemberForm = () => {
  const { gender, members: initialState } = useTypedSelector(
    (state) => state.landing
  );
  const dispatch = useDispatch();
  const [members, setMembers] = useState<MembersInterface[]>(
    initialState.filter(
      (item) => item.relation !== "son" && item.relation !== "daughter"
    )
  );
  const [sons, setSons] = useState<MembersInterface[]>(
    initialState.filter((item) => item.relation === "son")
  );
  const [daughters, setDaughters] = useState<MembersInterface[]>(
    initialState.filter((item) => item.relation === "daughter")
  );
  const onMemberClick = (e: React.MouseEvent, val: MembersInterface) => {
    if (members.some((member) => member.relation === val.relation)) {
      setMembers((prev) => {
        return prev.filter((member) => member.relation !== val.relation);
      });
    } else
      setMembers((prev) => {
        return [
          ...prev,
          {
            ...val,
          },
        ];
      });
  };
  const updateSelfGender = (gender: MembersInterface["gender"]) => {
    setMembers((prev) => {
      return prev.map((member) => {
        if (member.relation === "self") {
          return {
            ...member,
            gender,
          };
        } else return member;
      });
    });
  };
  useEffect(() => {
    updateSelfGender(gender);
  }, [gender]);
  const [error, setError] = useState("");

  useEffect(() => {
    if (members.length) {
      setError("");
    }
  }, [members]);
  const onSubmit = () => {
    if (!members.length) {
      setError("Please select self or spouse");
      return;
    }
    dispatch(
      setMemberDetails([
        ...members,
        ...sons.map((item, idx) => ({ ...item, count: idx + 1 })),
        ...daughters.map((item, idx) => ({ ...item, count: idx + 1 })),
      ])
    );
    dispatch(setActiveStep(1));
  };
  return (
    <>
      <Box>
        <CustomRadio />
      </Box>
      <Typography variant="body1" fontSize="16px" color={"black"}>
        Select members you want to insure
      </Typography>
      <Box maxWidth={"460px"} flexWrap={"wrap"} display="flex" gap={"20px"}>
        <MemberOption
          label="You"
          icon={gender === "male" ? "male" : "female"}
          value={{
            relation: "self",
            gender,
            age: 0,
          }}
          isSelected={members.some((item) => item.relation === "self")}
          onClick={onMemberClick}
        />

        <MemberOption
          label={gender === "male" ? "Wife" : "Husband"}
          icon={gender === "male" ? "female" : "male"}
          value={{
            relation: "spouse",
            gender: gender === "male" ? "female" : "male",
            age: 0,
          }}
          isSelected={members.some((item) => item.relation === "spouse")}
          onClick={onMemberClick}
        />

        <MemberOption
          label={"Son"}
          icon={"son"}
          value={{
            relation: "son",
            gender: "male",
            age: 0,
          }}
          disabled={!members.length}
          onIncreaseCount={() => {
            setSons((prev) => [
              ...prev,
              { relation: "son", gender: "male", age: 0 },
            ]);
          }}
          onDecreaseCount={() => {
            setSons((prev) => prev.slice(0, -1));
          }}
          initialCount={sons.length}
          showCount
          isSelected={Boolean(sons.length)}
          onClick={(_, value) => {
            if (sons.length === 0) {
              setSons((prev) => [
                ...prev,
                {
                  ...value,
                },
              ]);
            } else {
              setSons([]);
            }
          }}
        />

        <MemberOption
          label={"Daughter"}
          icon={"daughter"}
          value={{
            relation: "daughter",
            gender: "female",
            age: 0,
          }}
          disabled={!members.length}
          initialCount={daughters.length}
          onIncreaseCount={() => {
            setDaughters((prev) => [
              ...prev,
              { relation: "daughter", gender: "female", age: 0 },
            ]);
          }}
          onDecreaseCount={() => {
            setDaughters((prev) => prev.slice(0, -1));
          }}
          showCount
          isSelected={Boolean(daughters.length)}
          onClick={(_, value) => {
            if (daughters.length === 0) {
              setDaughters((prev) => [...prev, { ...value }]);
            } else {
              setDaughters([]);
            }
          }}
        />

        <MemberOption
          label="Father"
          icon={"father"}
          value={{
            relation: "father",
            gender: "male",
            age: 0,
          }}
          isSelected={members.some((item) => item.relation === "father")}
          onClick={onMemberClick}
        />

        <MemberOption
          label={"Mother"}
          icon={"mother"}
          value={{
            relation: "mother",
            gender: "female",
            age: 0,
          }}
          isSelected={members.some((item) => item.relation === "mother")}
          onClick={onMemberClick}
        />
      </Box>
      <Box textAlign={"center"} mb={2}>
        <Button
          sx={{
            backgroundColor: "#f2791f",
            color: "white",
            boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.02)",
            "&:hover": {
              backgroundColor: "#f64300",
              color: "white",
            },
          }}
          variant="contained"
          size="large"
          onClick={onSubmit}
        >
          Get Started
        </Button>

        <FormHelperText
          sx={(theme) => ({
            color: theme.palette.error.main,
          })}
          variant="filled"
        >
          {error}
        </FormHelperText>
      </Box>
    </>
  );
};

export default MemberForm;
const CustomRadio = () => {
  const { gender } = useTypedSelector((state) => state.landing);
  const dispatch = useDispatch();
  return (
    <>
      <div className="radio-wrapper">
        <Typography variant="body1" fontSize="16px" color={"black"} mt={4}>
          Proposer Gender
        </Typography>

        <div className="radio-round">
          <label
            className="radio-label"
            style={{ color: "black", marginTop: "30px" }}
          >
            <input
              type="radio"
              id="Male"
              name="gender"
              value="male"
              checked={gender === "male"}
              onChange={(e) => {
                dispatch(setGender(e.target.value));
              }}
            />
            <span className="custom-radio"></span>
            <span color={"black"}>Male</span>
          </label>
          <label
            className="radio-label"
            style={{ color: "black", marginTop: "30px" }}
          >
            <input
              type="radio"
              id="Female"
              name="gender"
              value="female"
              checked={gender === "female"}
              onChange={(e) => {
                dispatch(setGender(e.target.value));
              }}
            />
            <span className="custom-radio"></span>
            <span color={"black"}>Female</span>
          </label>
        </div>
      </div>
    </>
  );
};
