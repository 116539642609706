import { Box, LinearProgress, Typography, useTheme } from "@mui/material";
import countImg from "../../../../assets/images/count.svg";
import tickImg from "../../../../assets/images/tick.svg";
import { useGetQuotes } from "../../../../services/Quotes.service";

const QuoteCount = () => {
  const theme = useTheme();
  const { quoteLength, progressPercentage } = useGetQuotes();

  return (
    <>
      <Box
        display="flex"
        width="100%"
        sx={{
          px: 2,
          py: 1.5,
          background: "#ffede6",
          position: "relative",
          justifyContent: "space-between",
          "&::before": {
            content: `''`,
            position: "absolute",
            left: "0px",
            top: "12px",
            width: "4px",
            height: "24px",
            background: theme.palette.primary.main,
          },
          "@media (max-width: 576px)": {
            flexWrap: "wrap",
          },
        }}
      >
        <Box
          display="flex"
          ml={{
            md: 3,
            xs: 0,
          }}
          width={{
            xs: "100%",
            sm: "auto",
          }}
          gap="12px"
        >
          <img src={countImg} alt="Count" color="black" />
          <Typography
            fontWeight="bold"
            color="black"
            fontSize={{
              sm: "16px",
              xs: "12px",
            }}
          >
            {quoteLength} matching health insurance
          </Typography>
        </Box>

        <Box
          display="flex"
          ml={{
            sm: 3,
            xs: 0,
          }}
          width={{
            xs: "100%",
            sm: "auto",
          }}
          gap="12px"
        >
          <img src={tickImg} alt="Tick" color="black" />
          <Typography
            fontWeight="bold"
            color="black"
            fontSize={{
              sm: "16px",
              xs: "12px",
            }}
          >
            All plans are GST included
          </Typography>
        </Box>
      </Box>

      {progressPercentage !== 100 && (
        <LinearProgress
          sx={{
            width: "100%",
          }}
          variant="determinate"
          value={progressPercentage}
        />
      )}
    </>
  );
};

export default QuoteCount;
