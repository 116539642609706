import * as yup from "yup";

export const starInitialData = {
  portability_reason: "",
  members_covered_in_existing: {
    members: [],
  },
};

const validation = () =>
  yup.object().shape({
    portability_reason: yup.string().required("This field is required."),
    other_reason: yup.string().when("portability_reason", {
      is: "other",
      then: yup.string().required("This field is required."),
    }),
    members_covered_in_existing: yup.object().shape({
      members: yup.array().of(yup.string()).required("This field is required."),
    }),
    member_data: yup.lazy((value) =>
      yup.object(
        Object.keys(value || {}).reduce((acc, member) => {
          acc[member] = yup.object().shape({
            previous_policy_type: yup
              .string()
              .required("Previous policy type is required."),
            insurer_name: yup.string().required("Insurer name is required."),
            product_name: yup.string().required("Product name is required."),
            policy_type: yup.string().required("Policy type is required."),
            policy_inception_date: yup
              .date()
              .required("Policy inception date is required."),
            continous_coverage_year: yup
              .number()
              .typeError("Continuous coverage year must be a number.")
              .required("Continuous coverage year is required.")
              .min(1, "Coverage year must be at least 1.")
              .integer("Coverage year must be an integer."),
          });
          return acc;
        }, {})
      )
    ),
  });

export default validation;
