import { Close, EditOutlined } from "@mui/icons-material"; // Using EditOutlined icon
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SelectMenu from "../../../../components/SelectMenu/SelectMenu";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { setActiveStep } from "../../../../modules/landing.slice";
import { getMembers } from "../../../../utils/familyUtils";
import { getIcon, getMemberIcon } from "../../../../utils/iconUtils";
import membersIcon from "./../../../../assets/images/members.svg";
import { hover } from "@testing-library/user-event/dist/hover";

const ToolBar = () => {
  const theme = useTheme();
  const [showMembers, setShowMembers] = useState(false);
  const { members } = useTypedSelector((state) => state.landing);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <Box
        display={"flex"}
        justifyContent="space-between"
        width="100%"
        mt={{
          xs: "8px",
          sm: "24px",
        }}
        sx={{
          backgroundColor: "#ffe9e2",
          px: 2,
          py: 1.5,
          borderRadius: "5px 5px 0px 0px",
        }}
      >
        <Box
          display={"flex"}
          gap={{
            xs: "10px",
            sm: "20px",
          }}
          alignItems={"center"}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "32px",
              height: "32px",
              background: "#f2791f",
              borderRadius: "50%",
            }}
          >
            <img src={membersIcon} alt="members icon" />
          </Box>
          <Typography
            fontSize={{
              xs: "12px",
              sm: "16px",
            }}
            color="black"
            fontWeight="bold"
          >
            Member : {getMembers(members)}
          </Typography>
          <Button
            onClick={() => {
              setShowMembers(true);
            }}
            sx={{
              textTransform: "capitalize",
              fontSize: {
                xs: "12px",
                sm: "16px",
              },
              border: "1px solid black",
              color: "black",
              fontWeight: "bold",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              "&:hover": {
                backgroundColor: "#f2791f", // Fixed hover syntax
                color: "whitesmoke",
              },
            }}
          >
            <Tooltip title="Edit Details" arrow>
              <Box display="flex" alignItems="center" gap="8px">
                <EditOutlined fontSize="small" />
                Edit Details
              </Box>
            </Tooltip>
          </Button>
        </Box>
      </Box>

      <Dialog
        open={showMembers}
        onClose={() => {
          setShowMembers(false);
        }}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle
          sx={{
            position: "relative",
          }}
        >
          <Typography fontSize={"18px"} color="black" fontWeight="bold">
            Member Details
          </Typography>
          <IconButton
            sx={{
              position: "absolute",
              right: "10px",
              top: "8px",
            }}
            onClick={() => setShowMembers(false)}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box display={"flex"} flexDirection={"column"} gap={"20px"}>
            {members.map((member, index) => (
              <Box
                key={index}
                display={"flex"}
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Box display="flex" gap="20px" alignItems={"center"}>
                  <Box width="30px" height="30px">
                    <img
                      src={getMemberIcon(
                        getIcon(member.relation, member.gender)
                      )}
                      alt=""
                      width={"100%"}
                      height={"100%"}
                    />
                  </Box>
                  <Typography
                    fontSize={"16px"}
                    textTransform="capitalize"
                    color="text.secondary"
                  >
                    {member.relation}
                  </Typography>
                </Box>
                <Typography fontSize={"16px"} color="text.primary">
                  {member.age} years
                </Typography>
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => {
              navigate("/");
              dispatch(setActiveStep(0));
            }}
          >
            Edit Details
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ToolBar;
