export const reasonOptions = [
  {
    OPTION_KEY: "CB",
    OPTION_VAL: "CHEQUE BOUNCE",
  },
  {
    OPTION_KEY: "PAD",
    OPTION_VAL: "POLICY ADITION OR DELETION",
  },
  {
    OPTION_KEY: "PC",
    OPTION_VAL: "PRODUCT CHANGE",
  },
  {
    OPTION_KEY: "PM",
    OPTION_VAL: "POLICY MERGING",
  },
  {
    OPTION_KEY: "PS",
    OPTION_VAL: "POLICY SPLIT",
  },
];
export const prev_policy_type_option = [
  {
    OPTION_KEY: "individual",
    OPTION_VAL: "individual",
  },
  {
    OPTION_KEY: "group",
    OPTION_VAL: "group",
  },
];
export const insuranceCompanyNames = [
  {
    OPTION_KEY: "1001",
    OPTION_VAL: "THE NEW INDIA ASSURANCE CO. LTD.",
  },
  {
    OPTION_KEY: "1002",
    OPTION_VAL: "NATIONAL INSURANCE COMPANY LIMITED",
  },
  {
    OPTION_KEY: "1003",
    OPTION_VAL: "THE ORIENTAL INSURANCE CO. LTD",
  },
  {
    OPTION_KEY: "1004",
    OPTION_VAL: "UNITED INDIA INSURANCE CO. LTD.",
  },
  {
    OPTION_KEY: "1005",
    OPTION_VAL: "Apollo Munich Health Insurance Co Ltd",
  },
  {
    OPTION_KEY: "1006",
    OPTION_VAL: "STAR HEALTH AND ALLIED INSURANCE CO.LTD",
  },
  {
    OPTION_KEY: "1007",
    OPTION_VAL: "MAX Bupa Health Insurance Company Ltd.",
  },
  {
    OPTION_KEY: "1008",
    OPTION_VAL: "ICICI LOMBARD GENERAL INSURANCE CO. LTD.",
  },
  {
    OPTION_KEY: "1009",
    OPTION_VAL: "BAJAJ ALLIANZ GENERAL INSURANCE CO. LTD.",
  },
  {
    OPTION_KEY: "1010",
    OPTION_VAL: "RELIANCE GENERAL INSURANCE COMPANY LTD.",
  },
  {
    OPTION_KEY: "1011",
    OPTION_VAL: "TATA AIG GENERAL INSURANCE COMPANY LTD",
  },
  {
    OPTION_KEY: "1012",
    OPTION_VAL: "SBI GENERAL INSURANCE COMPANY LTD",
  },
  {
    OPTION_KEY: "1013",
    OPTION_VAL: "UNIVERSAL SOMPO GENERAL INSURANCE CO.LTD",
  },
  {
    OPTION_KEY: "1014",
    OPTION_VAL: "HDFC ERGO GENERAL INSURANCE COMPANY LTD",
  },
  {
    OPTION_KEY: "1015",
    OPTION_VAL: "FUTURE GENERALI INDIA INSURANCE CO.LTD",
  },
  {
    OPTION_KEY: "1016",
    OPTION_VAL: "BHARTI AXA GENERAL INSURANCE CO. LTD",
  },
  {
    OPTION_KEY: "1017",
    OPTION_VAL: "ROYAL SUNDARAM ALLIANCE INSURANCE CO LTD",
  },
  {
    OPTION_KEY: "1018",
    OPTION_VAL: "IFFCO TOKIO GENERAL INSURANCE CO. LTD.",
  },
  {
    OPTION_KEY: "1019",
    OPTION_VAL: "CHOLAMANDALAM MS GENERAL INSURANCE COLTD",
  },
  {
    OPTION_KEY: "1020",
    OPTION_VAL: "RAHEJA QBE GENERAL INS. CO. LTD",
  },
  {
    OPTION_KEY: "1021",
    OPTION_VAL: "SHRIRAM GENERAL INS. CO. LTD",
  },
  {
    OPTION_KEY: "1022",
    OPTION_VAL: "CARE HEALTH INSURANCE CO.",
  },
  {
    OPTION_KEY: "1023",
    OPTION_VAL: "L&T General Insurance Company Limited",
  },
  {
    OPTION_KEY: "1024",
    OPTION_VAL: "CignaTTK Health Insurance Company Limited",
  },
  {
    OPTION_KEY: "1025",
    OPTION_VAL: "LIBERTY VIDEOCON GENERAL INSURANCE Co. Ltd",
  },
  {
    OPTION_KEY: "1026",
    OPTION_VAL: "KOTAK GENERAL INSURANCE",
  },
  {
    OPTION_KEY: "1027",
    OPTION_VAL: "GROUP RETAIL PREVIOUS POLICY INSURER",
  },
  {
    OPTION_KEY: "1028",
    OPTION_VAL: "ADITYA BIRLA INSURANCE",
  },
  {
    OPTION_KEY: "1029",
    OPTION_VAL: "EDELWEISS HEALTH INSURANCE",
  },
  {
    OPTION_KEY: "1030",
    OPTION_VAL: "Magma HDI General Insurance Company Limited",
  },
  {
    OPTION_KEY: "1031",
    OPTION_VAL: "DIGIT HEALTH CARE PLUS POLICY",
  },
];
export const policy_type = [
  {
    OPTION_KEY: "floater",
    OPTION_VAL: "Floater",
  },
  {
    OPTION_KEY: "individual",
    OPTION_VAL: "Individual",
  },
];

export const continuous_coverage_year_options = [
  {
    OPTION_KEY: 1,
    OPTION_VAL: "1 year",
  },
  {
    OPTION_KEY: 2,
    OPTION_VAL: "2 year",
  },
  {
    OPTION_KEY: 3,
    OPTION_VAL: "3 year",
  },
  {
    OPTION_KEY: 4,
    OPTION_VAL: "4 year",
  },
];

export const claim_reason = [
  {
    OPTION_KEY: "X1014",
    OPTION_VAL: "Calculus of urinary tract",
  },
  {
    OPTION_KEY: "X0844",
    OPTION_VAL: "Cutaneous abscess, furuncle",
  },
  {
    OPTION_KEY: "X1061",
    OPTION_VAL: "Erosion and ectropion of cervical",
  },
  {
    OPTION_KEY: "X0339",
    OPTION_VAL: "Other immunodeficiencies",
  },
  {
    OPTION_KEY: "X0669",
    OPTION_VAL: "Cardiac arrest",
  },
  {
    OPTION_KEY: "X0229",
    OPTION_VAL: "Malignant neoplasm of kidney",
  },
  {
    OPTION_KEY: "X0710",
    OPTION_VAL: "Acute pharyngitis",
  },
  {
    OPTION_KEY: "X1445",
    OPTION_VAL: "Other and unspecified injuries of Forearm",
  },
  {
    OPTION_KEY: "X1021",
    OPTION_VAL: "Cystitis",
  },
  {
    OPTION_KEY: "X1080",
    OPTION_VAL: "Other abortion",
  },
  {
    OPTION_KEY: "X0478",
    OPTION_VAL: "Specific developmental disorders of speech and language",
  },
  {
    OPTION_KEY: "X1500",
    OPTION_VAL: "Injury of unspecified of body",
  },
  {
    OPTION_KEY: "X0587",
    OPTION_VAL: "Other retinal disorders",
  },
  {
    OPTION_KEY: "X0101",
    OPTION_VAL: "Unspecified viral hepatitis",
  },
  {
    OPTION_KEY: "X0709",
    OPTION_VAL: "Acute sinusitis",
  },
  {
    OPTION_KEY: "X0784",
    OPTION_VAL: "Other diseases of lip and oral",
  },
  {
    OPTION_KEY: "X1408",
    OPTION_VAL: "Fracture of rib(s), sternum",
  },
  {
    OPTION_KEY: "X0677",
    OPTION_VAL: "Intracerebral haemorrhage",
  },
  {
    OPTION_KEY: "X0619",
    OPTION_VAL: "Other disorders of middle ear",
  },
  {
    OPTION_KEY: "X0253",
    OPTION_VAL: "Lymphoid leukaemia",
  },
  {
    OPTION_KEY: "X0724",
    OPTION_VAL: "Acute bronchitis",
  },
  {
    OPTION_KEY: "X0604",
    OPTION_VAL: "Other disorders of eye and adnexa",
  },
  {
    OPTION_KEY: "X1038",
    OPTION_VAL: "Other disorders of penis",
  },
  {
    OPTION_KEY: "X0014",
    OPTION_VAL: "Tuberculosis of other organs",
  },
  {
    OPTION_KEY: "X1333",
    OPTION_VAL: "Dizziness and giddiness",
  },
  {
    OPTION_KEY: "X1019",
    OPTION_VAL: "Other disorder of kidney",
  },
  {
    OPTION_KEY: "X1132",
    OPTION_VAL: "Normal Delivery",
  },
  {
    OPTION_KEY: "X1134",
    OPTION_VAL: "Caesarean Delivery",
  },
  {
    OPTION_KEY: "X0010",
    OPTION_VAL: "Diarrhoea and gastroenteritis",
  },
  {
    OPTION_KEY: "X0817",
    OPTION_VAL: "Perianal Abscess",
  },
  {
    OPTION_KEY: "X0520",
    OPTION_VAL: "Epilepsy (Related to Seizure)",
  },
  {
    OPTION_KEY: "X0644",
    OPTION_VAL: "Angina Pectoris",
  },
  {
    OPTION_KEY: "X0268",
    OPTION_VAL: "Carcinoma Bladder",
  },
  {
    OPTION_KEY: "X0832",
    OPTION_VAL: "Cholecytitis",
  },
  {
    OPTION_KEY: "X0720",
    OPTION_VAL: "Bacterial Pneumonia, not elsewhere",
  },
  {
    OPTION_KEY: "X0645",
    OPTION_VAL: "Acute Myocardial Infection",
  },
  {
    OPTION_KEY: "X1230",
    OPTION_VAL: "Other Congenital malformation",
  },
  {
    OPTION_KEY: "X0002",
    OPTION_VAL: "Typhoid and Paratyphoid",
  },
  {
    OPTION_KEY: "X0649",
    OPTION_VAL: "Chronic ischemic heart disease",
  },
  {
    OPTION_KEY: "X0078",
    OPTION_VAL: "Dengue Fever",
  },
  {
    OPTION_KEY: "X0128",
    OPTION_VAL: "Plasmodium Falciparum Malaria(Smear Negative Malaria)",
  },
  {
    OPTION_KEY: "X1317",
    OPTION_VAL: "Localized swelling and Mass(Lipomotosis)",
  },
  {
    OPTION_KEY: "X0549",
    OPTION_VAL: "Other paralytic Syndromes",
  },
  {
    OPTION_KEY: "X1429",
    OPTION_VAL: "Dislocation sprain and strain",
  },
  {
    OPTION_KEY: "X1068",
    OPTION_VAL: "Fibroid Uterus",
  },
  {
    OPTION_KEY: "X0579",
    OPTION_VAL: "Pseudophakia",
  },
  {
    OPTION_KEY: "X1026",
    OPTION_VAL: "Urethral Stricture",
  },
  {
    OPTION_KEY: "X0801",
    OPTION_VAL: "Inguinal Hernia",
  },
  {
    OPTION_KEY: "X1612",
    OPTION_VAL: "Motor cycle rider injured to other and unspecified accident",
  },
  {
    OPTION_KEY: "X0309",
    OPTION_VAL: "Vitamin B12 deficiency anemia",
  },
  {
    OPTION_KEY: "X0267",
    OPTION_VAL: "Carcinoma in situ of other",
  },
  {
    OPTION_KEY: "X1202",
    OPTION_VAL: "Seizure or Respiratory distress in New born baby",
  },
  {
    OPTION_KEY: "X1341",
    OPTION_VAL: "Fever of unknown origin",
  },
  {
    OPTION_KEY: "X0808",
    OPTION_VAL: "Crohn's disease",
  },
  {
    OPTION_KEY: "X0284",
    OPTION_VAL: "Leiomyoma of uterus",
  },
  {
    OPTION_KEY: "X0794",
    OPTION_VAL: "Gastritis and duodenitis",
  },
  {
    OPTION_KEY: "X0293",
    OPTION_VAL: "Benign neoplasm of thyroid gland",
  },
  {
    OPTION_KEY: "X1448",
    OPTION_VAL: "Fracture at wrist and hand level",
  },
  {
    OPTION_KEY: "INA",
    OPTION_VAL: "Senile cataract",
  },
  {
    OPTION_KEY: "X0762",
    OPTION_VAL: "Pleural effusion, not elsewher",
  },
  {
    OPTION_KEY: "X0180",
    OPTION_VAL:
      "Malignant neoplasm of other and unspecified major salivary glands",
  },
  {
    OPTION_KEY: "X0360",
    OPTION_VAL: "Hypofunction and other disorders of pituitary gland",
  },
  {
    OPTION_KEY: "X0797",
    OPTION_VAL: "Acute appendicitis",
  },
  {
    OPTION_KEY: "X1468",
    OPTION_VAL: "Fracture of lower leg",
  },
  {
    OPTION_KEY: "X2073",
    OPTION_VAL: "Other abortion(D & C)",
  },
  {
    OPTION_KEY: "X1998",
    OPTION_VAL: "Care involving dialysis",
  },
  {
    OPTION_KEY: "X0604",
    OPTION_VAL: "Other disorders of eye",
  },
  {
    OPTION_KEY: "X0789",
    OPTION_VAL: "Disorders of oesophagus",
  },
  {
    OPTION_KEY: "X1069",
    OPTION_VAL: "Pain and other conditionsans and menstrual cycle",
  },
  {
    OPTION_KEY: "X1351",
    OPTION_VAL: "Oedema, not elsewhere classified",
  },
  {
    OPTION_KEY: "X0831",
    OPTION_VAL: "Cholelithiasis",
  },
  {
    OPTION_KEY: "X1045",
    OPTION_VAL: "Unspecified lump in breast",
  },
  {
    OPTION_KEY: "X0265",
    OPTION_VAL: "Carcinoma in situ of breast",
  },
  {
    OPTION_KEY: "X0679",
    OPTION_VAL: "Cerebral infarction",
  },
  {
    OPTION_KEY: "X1347",
    OPTION_VAL: "Convulsions, not elsewhere classified.",
  },
  {
    OPTION_KEY: "X0816",
    OPTION_VAL: "Fissure and fistula of anal and rectal regions",
  },
  {
    OPTION_KEY: "X0553",
    OPTION_VAL: "Other disorders of brain",
  },
  {
    OPTION_KEY: "X0011",
    OPTION_VAL: "Respiratory tuberculosis",
  },
  {
    OPTION_KEY: "X0804",
    OPTION_VAL: "Ventral hernia",
  },
  {
    OPTION_KEY: "X1395",
    OPTION_VAL: "Other and unspecified injuries",
  },
  {
    OPTION_KEY: "X0649",
    OPTION_VAL: "Other acute ischaemic heart",
  },
  {
    OPTION_KEY: "X0639",
    OPTION_VAL: "Essential (primary) hypertensi",
  },
  {
    OPTION_KEY: "X0222",
    OPTION_VAL: "Malignant neoplasm of ovary",
  },
  {
    OPTION_KEY: "X0132",
    OPTION_VAL: "Unspecified malaria",
  },
  {
    OPTION_KEY: "X0952",
    OPTION_VAL: "Spondylosis",
  },
  {
    OPTION_KEY: "X1386",
    OPTION_VAL: "Superficial injury of head",
  },
  {
    OPTION_KEY: "X0726",
    OPTION_VAL: "Unspecified acute lower respiratory infection",
  },
  {
    OPTION_KEY: "X0313",
    OPTION_VAL: "Thalassaemia",
  },
  {
    OPTION_KEY: "X0413",
    OPTION_VAL: "Other disorders of fluid",
  },
  {
    OPTION_KEY: "X1188",
    OPTION_VAL: "Neonatal jaundice from other",
  },
  {
    OPTION_KEY: "X1305",
    OPTION_VAL: "Abdominal and pelvic pain",
  },
  {
    OPTION_KEY: "X0731",
    OPTION_VAL: "Other disorders of nose",
  },
  {
    OPTION_KEY: "X0810",
    OPTION_VAL: "Other noninfective gastroenter",
  },
  {
    OPTION_KEY: "X1388",
    OPTION_VAL: "Fracture of skull and facial",
  },
  {
    OPTION_KEY: "X0680",
    OPTION_VAL: "Stroke, not specified as haemo",
  },
  {
    OPTION_KEY: "X0944",
    OPTION_VAL: "Other systemic involvement of",
  },
  {
    OPTION_KEY: "X0926",
    OPTION_VAL: "Other arthritis",
  },
  {
    OPTION_KEY: "X1010",
    OPTION_VAL: "Chronic renal failure",
  },
];

export const policyType = [
  {
    OPTION_KEY: "Individual",
    OPTION_VAL: "Individual",
  },
  {
    OPTION_KEY: "Floater",
    OPTION_VAL: "Floater",
  },
];
