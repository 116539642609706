import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { useGetProposalDetailsQuery } from "../../services/Proposal.service";
import complete from "./../../assets/images/complete.svg";
import logo from "../../assets/images/logo.png";
import { useGetPolicyDetails } from "../../services/ThankYou.service";
import { Download } from "@mui/icons-material";
import BackButton from "../../components/BackButton/BackButton";
import { useNavigate } from "react-router-dom";
import { Home } from "@mui/icons-material";
import { Link } from "react-router-dom";
const ThankYou = () => {
  const { product, quote_data, isProposalLoading } =
    useGetProposalDetailsQuery();
  const theme = useTheme();
  const { policy_data, isPolicyLoading, pdf } = useGetPolicyDetails();
  const navigate = useNavigate();
  return (
    <>
      <Container>
        <Paper
          sx={{
            background: "#FFFFFF",
            boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.02)",
            borderRadius: "5px",
            maxWidth: "80%",
            mx: "auto",
            mt: "80px",
            position: "relative",
            py: 5,
            [theme.breakpoints.down("md")]: {
              maxWidth: "100%",
              mt: "30px",
            },
          }}
        >
          {isPolicyLoading || isProposalLoading ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  height: "200px",
                  [theme.breakpoints.down("md")]: {
                    height: "100px",
                  },
                }}
              >
                <CircularProgress />
              </Box>
            </>
          ) : (
            <>
              {" "}
              <Box
                position="absolute"
                sx={{
                  top: "-50px",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <img src={complete} alt="complete" />
              </Box>
              {(policy_data?.ORDER_STAGE !== "PAYMENT FAILED" ||
                policy_data?.ORDER_STAGE === "PAYEMENT SUCCESS") && (
                <Typography
                  fontSize={{
                    md: "28px",
                    xs: "18px",
                  }}
                  color="text.secondary"
                  align="center"
                  mt={1}
                >
                  {" "}
                  Congratulations Your Health is Insured
                </Typography>
              )}
              {policy_data?.ORDER_STAGE === "PAYMENT FAILED" && (
                <Typography
                  fontSize={{
                    md: "28px",
                    xs: "18px",
                  }}
                  color="text.secondary"
                  align="center"
                  mt={1}
                >
                  {" "}
                  Sorry! Your payment has failed.
                </Typography>
              )}
              {(policy_data?.ORDER_STAGE === "PG SUCCESS" ||
                policy_data?.ORDER_STAGE === "PAYEMENT SUCCESS" ||
                policy_data?.ORDER_STAGE !== "PAYMENT FAILED") && (
                <Typography fontWeight="400" align="center" fontSize={"14px"}>
                  You will get your policy document in email.
                </Typography>
              )}
              <Box
                display="flex"
                justifyContent={"center"}
                gap="20px"
                mt={{
                  xs: 1,
                  md: 3,
                }}
              >
                {product.INSURANCE_SLUG === "niva_bupa" ? (
                  <Typography>Application No:</Typography>
                ) : (
                  <Typography
                    fontSize={{
                      xs: "12px",
                      md: "16px",
                    }}
                  >
                    Policy No:
                  </Typography>
                )}
                <Typography
                  fontSize={{
                    xs: "12px",
                    md: "16px",
                  }}
                  color="primary.main"
                >
                  {policy_data?.POLICY_NUMBER
                    ? policy_data?.POLICY_NUMBER
                    : policy_data?.PROPOSAL_NUMBER}
                </Typography>
              </Box>
              <Box
                width={{
                  xs: "100%",
                  md: "500px",
                }}
                mx="auto"
                mt={2}
                sx={{ border: "1px solid #E4E4E4", borderRadius: "5px" }}
                display="flex"
                flexWrap={"wrap"}
              >
                <Box
                  width="70%"
                  p={2}
                  sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    "&::after": {
                      content: '""',
                      width: "1px",
                      height: "80%",
                      background: "#E4E4E4",
                      display: "block",
                      position: "absolute",
                      right: 0,
                      top: "10%",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "60px",
                        height: "auto",
                        maxHeight: "50px",
                      }}
                    >
                      <img
                        width={"100%"}
                        height={"100%"}
                        src={product?.LOGO_PATH}
                        alt=""
                      />
                    </Box>
                    <Typography
                      fontSize={{
                        xs: "12px",
                        md: "16px",
                      }}
                      color="text.secondary"
                    >
                      {product?.INSURANCE_NAME}
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection={"column"} mt={3}>
                    <Typography
                      fontWeight={400}
                      fontSize={{
                        xs: "10px",
                        md: "12px",
                      }}
                    >
                      Policy Period:
                    </Typography>
                    <Typography
                      color="text.secondary"
                      fontSize={{
                        xs: "12px",
                        md: "14px",
                      }}
                    >
                      {quote_data?.TENURE || 1} years
                    </Typography>
                  </Box>
                </Box>
                <Box
                  width="30%"
                  p={2}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <img src={logo} alt="logo" width={"100%"} />
                  <Box>
                    <Typography
                      fontWeight={400}
                      fontSize={{
                        xs: "10px",
                        md: "12px",
                      }}
                    >
                      Sum Assured:
                    </Typography>
                    <Typography
                      color="text.secondary"
                      fontSize={{
                        xs: "12px",
                        md: "14px",
                      }}
                    >
                      {quote_data?.SUM_INSURED}
                    </Typography>
                  </Box>
                </Box>
                {(pdf || policy_data?.ORDER_STAGE === "PDF RECEIVED") && (
                  <Box
                    width={"100%"}
                    px={3}
                    py={2}
                    sx={{
                      position: "relative",
                      "&::before": {
                        content: '""',
                        width: "95%",
                        height: "1px",
                        background: "#E4E4E4",
                        display: "block",
                        position: "absolute",
                        top: 0,
                        left: "2.5%",
                      },
                    }}
                  >
                    <Button
                      component="a"
                      href={pdf}
                      target="_blank"
                      variant="contained"
                      fullWidth
                      size={"large"}
                    >
                      <Download />
                      Download Policy
                    </Button>
                  </Box>
                )}
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  mt={1}
                  px={3}
                  py={2}
                  sx={{
                    position: "relative",
                    "&::before": {
                      content: '""',
                      width: "95%",
                      height: "1px",
                      background: "#E4E4E4",
                      display: "block",
                      position: "absolute",
                      top: 0,
                      left: "2.5%",
                    },
                  }}
                >
                  <Button
                    variant="contained"
                    fullWidth
                    startIcon={<Home />}
                    size="large"
                    onClick={() => {
                      window.location.href = "/";
                    }}
                  >
                    Go To Home
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default ThankYou;
