import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.scss";
import Header from "./components/Header/Header";
import OldHeader from "./components/Header/OldHeader";
import Landing from "./pages/Landing/Landing";
import ProposalPage from "./pages/ProposalPage/ProposalPage";
import Quotes from "./pages/Quotes/Quotes";
import Riders from "./pages/Riders/Riders";
import ThankYou from "./pages/ThankYou/ThankYou";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Footer from "./components/Footer/Footer";
import ProposalSummary from "./pages/ProposalSummary/ProposalSummary";
import LeadCapture from "./pages/LeadCapture/LeadCapture";
import Compare from "./pages/Compare/Compare";
import useUrlQuery from "./hooks/useUrlQuery";

function App() {
  const { getUrlQuery } = useUrlQuery();
  const quote_id = getUrlQuery("quote_id");
  const propsal_id = getUrlQuery("proposal_id");
  return (
    <>
      {quote_id || propsal_id ? <OldHeader /> : ""}
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/quote" element={<Quotes />} />
        <Route path="/proposal" element={<ProposalPage />} />
        <Route path="/product-summary" element={<Riders />} />
        <Route path="/proposal-summary" element={<ProposalSummary />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/compare" element={<Compare />} />
        <Route path="/qr/:id" element={<LeadCapture />} />
      </Routes>
      <Footer />
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        limit={3}
        rtl={false}
        pauseOnHover
        theme="colored"
      />
      <ReactQueryDevtools />
    </>
  );
}

export default App;
