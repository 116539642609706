import { Button, Grid, Typography, AppBar, Toolbar } from "@mui/material"; // Removed Box import here
import Stepper from "../../components/Stepper/Stepper";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import AgeForm from "./forms/AgeForm";
import MemberForm from "./forms/MemberForm";
import PersonalForm from "./forms/PersonalForm";
import injuryamico from "./../../assets/images/injuryamico.png";
import ExistingIllness from "./forms/ExistingDisease";
import HealthBenefit from "./BenefitsPage";
import Sliders from "./Sliders";
import support from "../../assets/images/support.png";
import logo from "../../assets/images/logo.png";
import React from "react";
import { Box } from "@mui/material"; // Import Box only once
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

// Navbar for mobile view
const Navbar = () => {
  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: "#f2791f",
        padding: "10px",
        display: { xs: "flex", md: "none" }, // Only show on mobile
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Left: Logo */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            alt="Yella Insure"
            style={{ width: "50px", height: "50px" }}
          />
        </Box>

        {/* Right: Buttons */}
        <Box
          sx={{
            display: { xs: "flex", sm: "flex", md: "none" }, // Hide on tablets (sm)
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Button
            href="tel:+919288009111"
            sx={{
              backgroundColor: "#FFA07A",
              color: "#FFF",
              fontSize: "12px",
              "&:hover": {
                backgroundColor: "#FF8C69",
              },
              borderRadius: "20px",
            }}
            startIcon={
              <img width={"20px"} height={"20px"} src={support} alt="support" />
            }
          >
            +91 9288009111
          </Button>
          <Button
            href="https://account.vkover.com"
            sx={{
              backgroundColor: "#FFA07A",
              color: "#FFFFFF",
              textTransform: "none",
              fontSize: "12px",
              "&:hover": {
                backgroundColor: "#FF8C69",
              },
              borderRadius: "20px",
            }}
          >
            <i className="fas fa-user" style={{ marginRight: "8px" }}></i>
            Login
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const ActiveForm = () => {
  const { activeStep } = useTypedSelector((state) => state.landing);
  switch (activeStep) {
    case 0:
      return <MemberForm />;
    case 1:
      return <AgeForm />;
    case 2:
      return <ExistingIllness />;
    case 3:
      return <PersonalForm />;
    default:
      return null; // Avoid rendering an empty fragment
  }
};

const Landing = () => {
  const { activeStep } = useTypedSelector((state) => state.landing);

  return (
    <>
      {/* Mobile Navbar */}
      <Navbar />

      <Grid container spacing={2}>
        {/* Left Section */}
        <Grid item xs={12} md={7}>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              "& img": {
                width: {
                  xs: "0", // Hide image on mobile and tablet
                  sm: "80px", // Image size for small screens (tablet) and up
                  md: "90px", // Image size for medium screens (desktop) and up
                },
              },
              marginLeft: {
                xs: "20px",
                sm: "30px",
                md: "50px",
              },
              marginTop: "10px",
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
              },
            }}
          >
            <img src={logo} alt="Yella Insure" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minHeight: "520px",
              padding: "30px",
              gap: "30px",
            }}
          >
            <Stepper activeStep={activeStep} />
            <ActiveForm />
          </Box>
        </Grid>

        {/* Right Section */}
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            backgroundColor: "#f2791f",
            padding: "50px",
            color: "#FFFFFF",
            textAlign: "center",
            borderBottomLeftRadius: { xs: "0", md: "30px" },
            borderBottomRightRadius: { xs: "0", md: "30px" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              gap: "40px",
              flexWrap: "wrap",
              marginBottom: "20px",
              marginTop: "10px",
            }}
          >
            <Button
              href="https://www.yellainsure.com/"
              sx={{
                color: "#FFFFFF",
                textTransform: "none",
                fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "16px",
                },
                "&:hover": {
                  backgroundColor: "#FF8C69",
                },
                borderRadius: "20px",
              }}
            >
              Home
            </Button>
            <Button
              href="about"
              sx={{
                color: "#FFFFFF",
                textTransform: "none",
                fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "16px",
                },
                "&:hover": {
                  backgroundColor: "#FF8C69",
                },
                borderRadius: "20px",
              }}
            >
              About
            </Button>
            <Button
              variant="contained"
              href="tel:+919288009111"
              sx={{
                backgroundColor: "#FFA07A",
                color: "#FFF",
                fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "16px",
                },
                "&:hover": {
                  backgroundColor: "#FF8C69",
                },
                borderRadius: "20px",
              }}
              startIcon={
                <img
                  width={"20px"}
                  height={"20px"}
                  src={support}
                  alt="support"
                />
              }
            >
              +91 9288009111
            </Button>
            {/* Login Button */}
            <Button
              href="https://account.vkover.com"
              sx={{
                backgroundColor: "#FC9771",
                color: "#FFFFFF",
                textTransform: "none",
                fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "16px",
                },
                "&:hover": {
                  backgroundColor: "#FF8C69",
                },
                borderRadius: "20px",
                width: {
                  xs: "90px",
                  sm: "100px",
                  md: "120px",
                },
              }}
              startIcon={<FontAwesomeIcon icon={faUser} />} // Add FontAwesome user icon
            >
              Login
            </Button>
          </Box>

          <img
            src={injuryamico}
            alt="Insurance Info"
            style={{ width: "90%", maxWidth: "440px" }}
          />

          <Box
            sx={{
              backgroundColor: "white",
              boxShadow: "inset 0px 8px 10px rgba(0, 0, 0, 0.2)",
              padding: "30px",
              borderRadius: "10px",
              textAlign: "left",
              marginTop: "20px",
              width: "100%",
              maxWidth: "600px",
              marginLeft: "30px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "18px",
                fontWeight: "bold",
                lineHeight: 1.8,
                color: "#000",
                textAlign: "center",
              }}
            >
              Health insurance is not just a financial safety net; it's a
              promise of peace of mind, access to care, and the support you need
              to live a healthy, worry-free life.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Sliders />
          </Box>
        </Grid>
        {/* Health Benefit Section */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "800px",
            }}
          >
            <HealthBenefit />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Landing;
