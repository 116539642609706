import { Edit } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import {
  MembersInterface,
  setActiveStep,
  setMemberDetails,
} from "../../../modules/landing.slice";
import { v4 as uuid } from "uuid";
import { getAgeByRelation } from "../../../utils/ageUtils";
import { getMembers } from "../../../utils/familyUtils";
import { getIcon, getMemberIcon } from "../../../utils/iconUtils";

const AgeForm = () => {
  const { members } = useTypedSelector((state) => state.landing);
  const dispatch = useDispatch();
  const { handleChange, handleBlur, values, submitForm, errors, touched } =
    useFormik({
      initialValues: {
        member_details: members,
      },
      onSubmit: (values) => {
        dispatch(setMemberDetails(values.member_details));
        dispatch(setActiveStep(2));
      },
      validationSchema: yup.object().shape({
        member_details: yup
          .array()
          .of(
            yup.object().shape({
              age: yup
                .number()
                .required("Age is required")
                .positive("Age is required"),
            })
          )
          .test(
            "member_details",
            "Your kid has to be atleast 18 years younger than you and spouse",
            (value) => {
              if (!value) return false;
              const selfAge = value?.find(
                (member) => member.relation === "self"
              )?.age;
              const spouseAge = value?.find(
                (member) => member.relation === "spouse"
              )?.age;
              // @ts-ignore
              const eldestSonAge = value.reduce((prev, curr) => {
                if (curr.relation === "son") {
                  return (curr.age || 0) > prev ? curr.age : prev;
                } else return prev;
              }, 0);
              // @ts-ignore
              const eldestDaughterAge = value.reduce((prev, curr) => {
                if (curr.relation === "daughter") {
                  return (curr.age || 0) > prev ? curr.age : prev;
                } else return prev;
              }, 0);

              if (
                Math.min(selfAge || Infinity, spouseAge || Infinity) -
                  // @ts-ignore
                  Math.max(eldestDaughterAge || 0, eldestSonAge || 0) <
                18
              )
                return false;
              return true;
            }
          ),
      }),
    });

  return (
    <>
      <Typography
        component="p"
        fontSize="16px"
        fontWeight="400"
        color={"black"}
      >
        Buying Insurance for :{" "}
        <Button
          variant="text"
          onClick={() => {
            dispatch(setActiveStep(0));
          }}
        >
          {getMembers(members)}
          <Edit
            sx={{
              heigth: "16px",
              width: "16px",
              marginLeft: "8px",
            }}
          />
        </Button>
      </Typography>
      <Box mt={2}>
        {members.map((member, idx) => (
          <Grid container width="300px" mb={3} key={uuid()}>
            <Grid item xs={6} display="flex" alignItems={"center"}>
              {" "}
              <Avatar
                src={getMemberIcon(getIcon(member.relation, member.gender))}
              />
              <Typography
                sx={{
                  textTransform: "capitalize",
                  color: "text.secondary",
                  display: "inline-block ",
                }}
                ml={2}
              >
                {member.relation}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                size="small"
                sx={{
                  width: "160px",
                  fontSize: "16px",
                }}
              >
                <Select
                  variant="outlined"
                  sx={{ color: "black" }}
                  renderValue={(val) => {
                    if (!val) return <>Select Age</>;
                    else return <>{val} years</>;
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name={`member_details[${idx}].age`}
                  value={values.member_details[idx].age}
                  placeholder="Select Age"
                  color={"info"}
                  error={
                    !!(
                      touched.member_details &&
                      touched.member_details[idx] &&
                      errors.member_details &&
                      errors.member_details[idx]
                    )
                  }
                  MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                >
                  <MenuItem value={0}></MenuItem>
                  {getAgeByRelation(member.relation).map((age) => (
                    <MenuItem value={parseInt(age)}>{age}</MenuItem>
                  ))}
                </Select>
                <Typography
                  variant="caption"
                  position={"absolute"}
                  top={"100%"}
                  color="error"
                  pl={1}
                >
                  {touched.member_details &&
                    touched.member_details[idx] &&
                    errors.member_details &&
                    /* @ts-ignore*/
                    errors.member_details[idx]?.age}
                </Typography>
              </FormControl>
            </Grid>
          </Grid>
        ))}
      </Box>
      <Typography variant="caption" color="error" pl={1}>
        {/* @ts-ignore*/}
        {typeof errors.member_details === "string" && errors.member_details}
      </Typography>
      <Button
        sx={{
          backgroundColor: "#f2791f",
          color: "white",
          boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.02)",
          "&:hover": {
            backgroundColor: "#f64300",
            color: "white",
          },
          mb: 2,
        }}
        onClick={() => {
          submitForm();
        }}
        variant="contained"
        size="large"
      >
        Continue
      </Button>
    </>
  );
};

export default AgeForm;
