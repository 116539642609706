import {
  Button,
  Divider,
  Grid,
  Typography,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";
import { Form, useFormikContext, Formik } from "formik";
import Select from "../../../InputComponents/Select";
import {
  continuous_coverage_year_options,
  insuranceCompanyNames,
  policy_type,
  prev_policy_type_option,
  reasonOptions,
} from "../options/star";
import TextInput from "../../../InputComponents/TextInput";
import Toggle from "../../../InputComponents/Toggle";
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector";
import DateComp from "../../../InputComponents/DateComp";
import moment from "moment";
import YesNo from "../../../InputComponents/YesNo";
import { allowOnlyNumbers } from "../../../../../../utils/inputUtils";
import { useEffect, useState } from "react";
import Radio from "../../../InputComponents/Radio";
import { setProposalData } from "../../../../../../modules/proposal.slice";
import {
  useGetProposalDetailsQuery,
  useUpdateProposalFormData,
  useUploadPortingFile,
} from "../../../../../../services/Proposal.service";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";
import PortablityToggle from "../../../InputComponents/PortablityToggle";
import validation from "../validations/starvalidation";

const PortabilityStar = () => {
  const navigate = useNavigate();
  const { values, submitForm, errors, setFieldValue } = useFormikContext();
  const { proposalData } = useTypedSelector((state) => state.proposal);
  const { isPortability } = useGetProposalDetailsQuery();
  const { saveForm } = useUpdateProposalFormData(() => {});
  const { upload, isUploadLoading } = useUploadPortingFile();
  const dispatch = useDispatch();
  const intialValues = {
    ...(proposalData?.portability_details || {}),
    ...(proposalData?.ckyc_details || {}),
    app_url: proposalData?.portability_details?.app_url || "",
  };

  const handleSubmit = async (values) => {
    try {
      const submissionData = {
        portability_details: {
          ...values,
        },
      };
      await saveForm(submissionData);
      dispatch(setProposalData(submissionData));

      if (isPortability) {
        navigate({
          pathname: "/proposal-summary",
          search: window.location.search,
        });
      }
    } catch (error) {
      console.error("Error during form submission:", error);
    }
  };

  return (
    <>
      <Formik
        initialValues={intialValues}
        onSubmit={handleSubmit}
        validationSchema={validation}
      >
        {({ submitForm, values, setFieldValue }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Select
                  name="portability_reason"
                  label={"Reason For Porting"}
                  options={reasonOptions}
                />
              </Grid>
              {values.portability_reason === "other" && (
                <Grid item xs={12} md={6}>
                  <TextInput label="Please Specify" name="other_reason" />
                </Grid>
              )}

              <Grid item xs={12}>
                <PortablityToggle
                  name="members_covered_in_existing"
                  label="Which members are covered in existing policy?"
                />
              </Grid>

              {values.members_covered_in_existing &&
                values.members_covered_in_existing.members?.map((item) => (
                  <>
                    {" "}
                    <Grid item xs={12}>
                      <Typography
                        mt={2}
                        color="primary.main"
                        textTransform={"capitalize"}
                      >
                        {proposalData.insurer_details[item]?.first_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Select
                        options={prev_policy_type_option}
                        touched
                        name={`member_data.${item}.previous_policy_type`}
                        label="Previous Policy Type"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Select
                        options={insuranceCompanyNames}
                        touched
                        name={`member_data.${item}.insurer_name`}
                        label="Previous Insurance"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextInput
                        name={`member_data.${item}.product_name`}
                        touched
                        label="Product Name"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Select
                        options={policy_type}
                        name={`member_data.${item}.policy_type`}
                        touched
                        label="Policy Type"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DateComp
                        label="Policy Inception Date"
                        name={`member_data.${item}.policy_inception_date`}
                        touched
                        maxDate={moment().subtract(1, "year")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Select
                        name={`member_data.${item}.continous_coverage_year`}
                        touched
                        options={continuous_coverage_year_options}
                        label="Continous Coverage Year"
                      />
                    </Grid>
                    {[
                      ...Array(
                        values.member_data?.[item]?.continous_coverage_year
                      ),
                    ]?.map((_, coverage_count) => (
                      <>
                        <Grid item xs={12}>
                          <Typography
                            mt={2}
                            color="primary.main"
                            textTransform={"capitalize"}
                          >
                            Coverage year {coverage_count + 1} (
                            {proposalData.insurer_details[item]?.first_name})
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextInput
                            label="Policy Number"
                            touched
                            name={`member_data.${item}.policy_details.${coverage_count}.policy_no`}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextInput
                            label="Customer Id"
                            touched
                            name={`member_data.${item}.policy_details.${coverage_count}.policy_cust_id`}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <DateComp
                            label="Policy From Date"
                            touched
                            name={`member_data.${item}.policy_details.${coverage_count}.policy_from_date`}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <DateComp
                            label="Policy End Date"
                            touched
                            name={`member_data.${item}.policy_details.${coverage_count}.policy_to_date`}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextInput
                            label="Sum Insured"
                            touched
                            name={`member_data.${item}.policy_details.${coverage_count}.sum_insured`}
                            onInput={allowOnlyNumbers}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextInput
                            label="Cumulative Bonus"
                            touched
                            name={`member_data.${item}.policy_details.${coverage_count}.cumulative_bonus`}
                            onInput={allowOnlyNumbers}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          {values.member_data?.[item]?.policy_details?.[
                            coverage_count
                          ]?.app_url ? (
                            <Box display="flex" flexDirection="column">
                              <Typography
                                fontSize="16px"
                                fontWeight="500"
                                marginBottom="8px"
                              >
                                View Insurance Document
                              </Typography>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                padding="1px"
                                border="1px solid #ccc"
                                borderRadius="4px"
                              >
                                <Box
                                  flexGrow={1}
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  padding="4px"
                                >
                                  <a
                                    href={
                                      values.member_data?.[item]
                                        ?.policy_details?.[coverage_count]
                                        ?.app_url
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Insurance Document
                                  </a>
                                </Box>
                                <Tooltip title="Clear">
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue(
                                        `member_data.${item}.policy_details.${coverage_count}.app_url`,
                                        ""
                                      )
                                    }
                                    aria-label="clear"
                                  >
                                    <Close />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>
                          ) : (
                            <TextInput
                              type="file"
                              name={`member_data.${item}.policy_details.${coverage_count}.app_url`}
                              label="Upload Insurance Document"
                              onChange={async (e) => {
                                const file = e.target.files[0];
                                if (file) {
                                  const formData = new FormData();
                                  formData.append("file", file);
                                  try {
                                    const response = await upload(formData);
                                    const uploadedUrl = response?.data?.app_url;
                                    if (uploadedUrl) {
                                      setFieldValue(
                                        `member_data.${item}.policy_details.${coverage_count}.app_url`,
                                        uploadedUrl
                                      );
                                    }
                                  } catch (error) {
                                    console.error("File upload failed:", error);
                                  }
                                }
                              }}
                            />
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          <Radio
                            label="Did you make any claim?"
                            touched
                            name={`member_data.${item}.policy_details.${coverage_count}.is_claim_made`}
                          />
                        </Grid>
                        {values?.member_data?.[item]?.policy_details?.[
                          coverage_count
                        ]?.is_claim_made === "Y" && (
                          <>
                            <Grid item xs={12} md={6}>
                              <TextInput
                                touched
                                name={`member_data.${item}.policy_details.${coverage_count}.no_of_claims`}
                                onInput={allowOnlyNumbers}
                                maxLength={3}
                                label="No of Claims"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextInput
                                label="Total Claim Amount"
                                touched
                                name={`member_data.${item}.policy_details.${coverage_count}.claim_amount`}
                                onInput={allowOnlyNumbers}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextInput
                                label="Name of Illness"
                                touched
                                name={`member_data.${item}.policy_details.${coverage_count}.illness_claim`}
                              />
                            </Grid>
                          </>
                        )}
                      </>
                    ))}
                  </>
                ))}
              <Grid
                item
                xs={12}
                justifyContent="center"
                display={"flex"}
                my={1}
              >
                <Button
                  size="large"
                  variant="contained"
                  onClick={() => {
                    submitForm();
                  }}
                >
                  {"Proceed to Review & Pay"}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PortabilityStar;
