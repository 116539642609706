import { Grid, Stack } from "@mui/material";
import { useGetQuotes } from "../../../../services/Quotes.service";
import QuoteCard from "../QuoteCard/QuoteCard";

const QuoteContainer = () => {
  const { groupedQuotes } = useGetQuotes();
  return (
    <Grid container>
      <Grid item xs={12} sm={12} sx={{ marginTop: "20px" }}>
        <Stack>
          {Object.keys(groupedQuotes).map((company, company_idx) => {
            return groupedQuotes[company].map((quote, quote_idx) => {
              return (
                <QuoteCard
                  quoteData={quote.quoteData}
                  isLoading={quote.isLoading}
                  showMore={
                    quote_idx === 0 && groupedQuotes[company].length > 1
                  }
                  no_of_more_plans={groupedQuotes[company].length - 1}
                  index={quote_idx}
                />
              );
            });
          })}
          {/* {fetchedQuotes.map((quote) => (
            <QuoteCard
              quoteData={quote.quoteData}
              isLoading={quote.isLoading}
            />
          ))} */}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default QuoteContainer;
