import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  FormControlLabel,
  Grid,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import useUrlQuery from "../../../../hooks/useUrlQuery";
import {
  openViewPlanPopup,
  setShowMorePlans,
  updatePlansForCompare,
} from "../../../../modules/quotes.slice";
import { useInitiateProposalMuation } from "../../../../services/Proposal.service";
import { GetQuotesResponseInterface } from "../../../../services/Quotes.service";
import {
  commaSeparateNumber,
  showAmountInWords,
} from "../../../../utils/priceUtils";
import QuoteData from "./components/QuoteData";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEye";
interface QuoteCardProps {
  quoteData: GetQuotesResponseInterface | undefined;
  isLoading?: boolean;
  showMore?: boolean;
  no_of_more_plans?: number;
  index: number;
}
const QuoteCard: React.FC<QuoteCardProps> = ({
  quoteData,
  isLoading,
  showMore,
  no_of_more_plans,
  index,
  ...props
}) => {
  const { initiateProposal, isProposalInitiating } =
    useInitiateProposalMuation();
  const { getUrlQuery } = useUrlQuery();
  const quote_id = getUrlQuery("quote_id");
  const dispatch = useDispatch();
  const PRODUCT_DATA = quoteData?.PRODUCT_DATA;
  const PREMIUM_DATA = quoteData?.PREMIUM_DATA.PREMIUM_DATA;
  const PRODUCT_FEATURES =
    quoteData?.PRODUCT_FEATURES ||
    (Array(5) as GetQuotesResponseInterface["PRODUCT_FEATURES"]);
  const { showMorePlans, selected_plan_for_compare_product_ids } =
    useTypedSelector((state) => state.quote);
  const onBuyNowClick = () => {
    if (isProposalInitiating) return;
    initiateProposal({
      QUOTE_ID: quote_id,
      PRODUCT_ID: PRODUCT_DATA?.PRODUCTID,
      COMPANY_SLUG: PRODUCT_DATA?.INSURANCE_SLUG,
      TOTAL_PREMIUM: PREMIUM_DATA?.TOTAL_PREMIUM,
      NET_PREMIUM: PREMIUM_DATA?.NET_PREMIUM,
      SUM_INSURED: PREMIUM_DATA?.COVER_AMOUNT,
    });
  };
  const isMobile = useMediaQuery("(max-width:768px)");
  const isShowMoreSelected =
    showMorePlans[
      quoteData?.PRODUCT_DATA?.INSURANCE_SLUG?.split("_")
        .slice(0, 2)
        .join("_") || 0
    ];
  const onShowMoreClick = () => {
    dispatch(
      setShowMorePlans({
        key: PRODUCT_DATA?.INSURANCE_SLUG.split("_").slice(0, 2).join("_"),
        value: !isShowMoreSelected,
      })
    );
  };
  if (isMobile) {
    return (
      <>
        <Collapse
          in={(index > 0 && isShowMoreSelected) || !index}
          sx={{ boxShadow: "0px 8px 20px 5px rgba(219, 219, 219, 0.25)" }}
        >
          <Grid
            container
            sx={{
              background: "#FFFFFF",
              borderRadius: "10px",
              p: isMobile ? 1 : 2,
              py: isMobile ? 2 : 4,
              pt: 1,
              position: "relative",
            }}
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {isLoading ? (
                <>
                  <Skeleton variant="rectangular" width={100} height={40} />
                  <Skeleton variant="text" width={100} height={20} />
                </>
              ) : (
                <>
                  <img
                    src={PRODUCT_DATA?.LOGO_PATH}
                    alt=""
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      maxHeight: "40px",
                    }}
                  />
                  <Typography
                    variant="caption"
                    fontSize={isMobile ? "8px" : "10px"}
                  >
                    {PRODUCT_DATA?.INSURANCE_NAME}
                  </Typography>
                </>
              )}
            </Grid>

            <Grid item xs={isMobile ? 8 : 6}>
              <Box
                sx={{
                  fontSize: isMobile ? "16px" : "22px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
              >
                {PRODUCT_DATA?.PRODUCT_NAME}
              </Box>
              <Box
                display="flex"
                flexWrap="wrap"
                gap={isMobile ? "4px" : "6px"}
              >
                <Chip
                  variant="outlined"
                  sx={{
                    borderRadius: "6px",
                    backgroundColor: "#ffede6",
                    color: "black",
                  }}
                  label={showAmountInWords(PREMIUM_DATA?.COVER_AMOUNT)}
                />
                {PRODUCT_FEATURES?.map((item) => (
                  <Chip
                    key={item?.FEATURE_TITLE}
                    sx={{
                      borderRadius: "6px",
                      backgroundColor: "#ffede6",
                      color: "black",
                    }}
                    variant="outlined"
                    label={item?.USP_TEXT}
                  />
                ))}
                <Tooltip title="Click to see all features">
                  <Chip
                    icon={
                      <RemoveRedEyeOutlinedIcon
                        sx={{
                          color: "#f2791f",
                          "&:hover": { color: "white" },
                        }}
                      />
                    }
                    label="View All Features"
                    sx={{
                      color: "#f2791f",
                      backgroundColor: "#ffede6",
                      "&:hover": { backgroundColor: "#f2791f", color: "white" },
                    }}
                    variant="outlined"
                    onClick={() => {
                      dispatch(
                        openViewPlanPopup({
                          product_id: PRODUCT_DATA?.PRODUCTID,
                          quote_data: {
                            logo: PRODUCT_DATA?.LOGO_PATH,
                            premium: PREMIUM_DATA?.TOTAL_PREMIUM,
                            cover: PREMIUM_DATA?.COVER_AMOUNT,
                          },
                        })
                      );
                    }}
                  />
                </Tooltip>
              </Box>
            </Grid>

            <Grid item xs={isMobile ? 12 : 4} height="100%">
              <Grid
                container
                columnSpacing={1}
                rowSpacing={1}
                height="100%"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12}>
                  {isLoading ? (
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="40px"
                    />
                  ) : (
                    <Stack>
                      <Tooltip title="Click to buy">
                        <Button
                          variant="contained"
                          fullWidth
                          sx={{
                            top: "10px",
                            background: "#f2791f",
                            color: "white",
                            fontWeight: "bold",
                            "&:hover": { backgroundColor: "#149f00" },
                          }}
                          size={isMobile ? "small" : "medium"}
                          onClick={onBuyNowClick}
                        >
                          {isProposalInitiating ? (
                            <CircularProgress
                              size={30}
                              sx={{ color: "white" }}
                            />
                          ) : (
                            `${commaSeparateNumber(
                              PREMIUM_DATA?.TOTAL_PREMIUM
                            )}/year`
                          )}
                        </Button>
                      </Tooltip>
                      <Button
                        sx={{
                          background: "#016D91",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px",
                          borderRadius: "8px",
                          color: "white",
                          textTransform: "none",
                          "&:hover": { background: "#014F6D" },
                          top: "15px",
                        }}
                      >
                        <FormControlLabel
                          sx={{
                            mr: 0,
                            ml: 0,
                            fontSize: isMobile ? "12px" : "14px",
                            "& .MuiFormControlLabel-label": {
                              fontSize: isMobile ? "12px" : "14px",
                              color: "white",
                            },
                          }}
                          checked={false} // Set the value based on your logic
                          control={
                            <Checkbox
                              sx={{
                                padding: "0 4px",
                                transform: "scale(0.8)",
                                color: "white",
                                "&.Mui-checked": { color: "white" },
                              }}
                            />
                          }
                          label="Compare"
                          onChange={(e, checked) => {
                            dispatch(
                              updatePlansForCompare({
                                cover_amount: PREMIUM_DATA?.COVER_AMOUNT,
                                product_id: PRODUCT_DATA?.PRODUCTID,
                                slug: PRODUCT_DATA?.INSURANCE_SLUG,
                              })
                            );
                          }}
                        />
                      </Button>
                    </Stack>
                  )}
                </Grid>
              </Grid>
            </Grid>

            {showMore && !isLoading && (
              <Chip
                sx={{ top: "18px", bottom: "0", left: "30px" }}
                onClick={onShowMoreClick}
                variant="filled"
                size="small"
                color="secondary"
                label={`Show ${no_of_more_plans} more plans`}
                icon={isShowMoreSelected ? <ExpandLess /> : <ExpandMore />}
              />
            )}
          </Grid>
        </Collapse>
      </>
    );
  }
  return (
    <Collapse
      in={(index > 0 && isShowMoreSelected) || !index}
      sx={{
        boxShadow: "0px 8px 20px 5px rgba(219, 219, 219, 0.25)",
      }}
    >
      <Grid
        container
        sx={{
          background: "#FFFFFF",
          // boxShadow: "0px 8px 20px 5px rgba(219, 219, 219, 0.25)",
          borderRadius: "10px",
          p: 2,
          py: 4,
          pt: 1,
          ml: 0,
          mt: 1,
          position: "relative",
        }}
        spacing={4}
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            "& img": {
              maxWidth: "100%",
              height: "40px",
              width: "auto",
            },
          }}
        >
          {isLoading ? (
            <>
              <Skeleton variant="rectangular" width={100} height={40} />
              <Skeleton variant="text" width={100} height={20} />
            </>
          ) : (
            <>
              <img src={PRODUCT_DATA?.LOGO_PATH} alt="" />{" "}
              <Typography variant="caption" fontSize={"10px"}>
                {PRODUCT_DATA?.INSURANCE_NAME}
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs={8}>
          <Box
            sx={{ fontSize: "22px", fontWeight: "bold", marginBottom: "10px" }}
          >
            {PRODUCT_DATA?.PRODUCT_NAME}
          </Box>
          <Box display="flex" flexWrap="wrap" gap="6px">
            <Chip
              variant="outlined"
              sx={{
                borderRadius: "6px",
                backgroundColor: "#ffede6",
                color: "black",
              }}
              label={showAmountInWords(PREMIUM_DATA?.COVER_AMOUNT)}
            ></Chip>
            {PRODUCT_FEATURES?.map((item) => {
              return (
                <Chip
                  sx={{
                    borderRadius: "6px",
                    backgroundColor: "#ffede6",
                    color: "black",
                  }}
                  variant="outlined"
                  label={item?.USP_TEXT}
                />
              );
            })}
            <Tooltip title={"Click to see all features"}>
              <Chip
                icon={
                  <RemoveRedEyeOutlinedIcon
                    sx={{
                      color: "#f2791f !important",
                      "&:hover": {
                        color: "white !important",
                      },
                      "&:hover .MuiSvgIcon-root": {
                        color: "white",
                      },
                    }}
                  />
                }
                label="View All Features"
                sx={{
                  color: "#f2791f",
                  backgroundColor: "#ffede6",
                  "&:hover": {
                    backgroundColor: "#f2791f !important",
                    color: "white !important",
                  },
                }}
                variant="outlined"
                onClick={() => {
                  dispatch(
                    openViewPlanPopup({
                      product_id: PRODUCT_DATA?.PRODUCTID,
                      quote_data: {
                        logo: quoteData?.PRODUCT_DATA?.LOGO_PATH,
                        premium:
                          quoteData?.PREMIUM_DATA?.PREMIUM_DATA?.TOTAL_PREMIUM,
                        cover:
                          quoteData?.PREMIUM_DATA?.PREMIUM_DATA?.COVER_AMOUNT,
                      },
                    })
                  );
                }}
              />
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={2} height="100%">
          <Grid
            container
            columnSpacing={1}
            rowSpacing={1}
            height="100%"
            alignItems={"center"}
            justifyContent={"center"}
          >
            {/* <Grid
              item
              xs={12}
              gap={"10px"}
              display='flex'
              justifyContent={"center"}
            >
              <Grid item xs={6}>
                {isLoading ? (
                  <Skeleton variant='rectangular' width='100%' height='40px' />
                ) : (
                  <Button
                    variant='outlined'
                    fullWidth
                    color='secondary'
                    size='small'
                    sx={{
                      px: 0,
                      py: "2px",
                    }}
                  >
                    Add to Shortlist
                  </Button>
                )}
              </Grid>
              <Grid item xs={6}>
                {isLoading ? (
                  <Skeleton variant='rectangular' width='100%' height='40px' />
                ) : (
                  <Button
                    variant='outlined'
                    fullWidth
                    color='secondary'
                    size='small'
                    sx={{
                      px: 0,
                      py: "2px",
                    }}
                  >
                    Compare
                  </Button>
                )}
              </Grid>
            </Grid> */}
            <Grid
              item
              xs={12}
              gap={"10px"}
              display="flex"
              justifyContent={"center"}
            >
              {/* <Grid item xs={6}>
                {isLoading ? (
                  <Skeleton variant='rectangular' width='100%' height='40px' />
                ) : (
                  <Button
                    onClick={() => {
                      dispatch(
                        openViewPlanPopup({
                          product_id: PRODUCT_DATA?.PRODUCTID,
                          quote_data: {
                            logo: quoteData?.PRODUCT_DATA?.LOGO_PATH,
                            premium:
                              quoteData?.PREMIUM_DATA?.PREMIUM_DATA
                                ?.TOTAL_PREMIUM,
                            cover:
                              quoteData?.PREMIUM_DATA?.PREMIUM_DATA
                                ?.COVER_AMOUNT,
                          },
                        })
                      );
                    }}
                    variant='outlined'
                    fullWidth
                    color='primary'
                    size='medium'
                  >
                    View Plan
                  </Button>
                )}
              </Grid> */}
              <Grid item xs={12}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height="40px" />
                ) : (
                  <Stack>
                    <Tooltip title={"Click to buy"}>
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{
                          background: "#f2791f",
                          color: "white",
                          fontWeight: "bold",
                          "&:hover": {
                            backgroundColor: "#149f00",
                          },
                        }}
                        size="medium"
                        onClick={onBuyNowClick}
                      >
                        {isProposalInitiating ? (
                          <CircularProgress
                            size={30}
                            sx={{
                              color: "white",
                            }}
                          />
                        ) : (
                          `${commaSeparateNumber(
                            PREMIUM_DATA?.TOTAL_PREMIUM
                          )}/year`
                        )}
                      </Button>
                    </Tooltip>
                    <Button
                      sx={{
                        background: "#016D91",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "10px",
                        borderRadius: "8px",
                        color: "white",
                        textTransform: "none",
                        "&:hover": {
                          background: "#014F6D",
                        },
                        top: "15px",
                      }}
                    >
                      <FormControlLabel
                        sx={{
                          mr: 0,
                          ml: 0,
                          position: "relative",
                          fontSize: "14px", // Adjust the font size for the label
                          "& .MuiFormControlLabel-label": {
                            fontSize: "14px", // Small font size for label text
                            color: "white", // Label color
                          },
                        }}
                        checked={selected_plan_for_compare_product_ids.some(
                          (item: any) => {
                            return (
                              item.product_id ===
                                quoteData?.PRODUCT_DATA.PRODUCTID &&
                              item.cover_amount ===
                                quoteData?.PREMIUM_DATA?.PREMIUM_DATA
                                  ?.COVER_AMOUNT
                            );
                          }
                        )}
                        control={
                          <Checkbox
                            sx={{
                              padding: "0 4px", // Reduce padding for a smaller size
                              transform: "scale(0.8)", // Scale down the checkbox
                              color: "white",
                              "&.Mui-checked": {
                                color: "white",
                              },
                            }}
                          />
                        }
                        label="Compare"
                        onChange={(e, checked) => {
                          dispatch(
                            updatePlansForCompare({
                              cover_amount:
                                quoteData?.PREMIUM_DATA.PREMIUM_DATA
                                  .COVER_AMOUNT,
                              product_id: quoteData?.PRODUCT_DATA.PRODUCTID,
                              slug: quoteData?.PRODUCT_DATA.INSURANCE_SLUG,
                            })
                          );
                        }}
                      />
                    </Button>
                  </Stack>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {showMore && !isLoading && (
          <Chip
            sx={{
              position: "absolute",
              fontSize: "12px",
              left: "12px",
              bottom: "0",
              borderRadius: 0,
            }}
            onClick={onShowMoreClick}
            variant="filled"
            size="small"
            color="secondary"
            label={`Show ${no_of_more_plans} more plans`}
            icon={isShowMoreSelected ? <ExpandLess /> : <ExpandMore />}
          ></Chip>
        )}
      </Grid>
    </Collapse>
  );
};

export default QuoteCard;
