import {
  AppBar,
  Box,
  Button,
  Container,
  Toolbar,
  Tooltip,
} from "@mui/material";
import logo from "../../assets/images/logo.png";
import support from "../../assets/images/support.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import useUrlQuery from "../../hooks/useUrlQuery";

const OldHeader = () => {
  const { getUrlQuery } = useUrlQuery();
  const quote_id = getUrlQuery("quote_id");
  const proposal_id = getUrlQuery("proposal_id");

  return (
    <AppBar position="static" color="transparent">
      <Container>
        <Toolbar
          sx={{
            padding: "0 !important",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* Logo Section */}
          <Box
            display="flex"
            gap="20px"
            alignItems="center"
            sx={{
              "& img": {
                width: {
                  xs: "60px",
                  sm: "100px",
                },
              },
            }}
          >
            <a href="/">
              <Tooltip title="Go To Home">
                <img src={logo} alt="logo" />
              </Tooltip>
            </a>
          </Box>

          {/* Buttons Section */}
          <Box
            display="flex"
            gap={{
              xs: "10px",
              sm: "20px",
            }}
          >
            <Button
              variant="contained"
              href="tel:+919288009111"
              sx={{
                backgroundColor: "#f2791f",
                color: "#FFF",
                fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "16px",
                },
                "&:hover": {
                  backgroundColor: "#FF8C69",
                },
                borderRadius: "20px",
              }}
              startIcon={
                <img width="20px" height="20px" src={support} alt="support" />
              }
            >
              +91 9288009111
            </Button>
            <Button
              href="https://account.vkover.com"
              sx={{
                backgroundColor: "#f2791f",
                color: "#FFFFFF",
                textTransform: "none",
                fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "16px",
                },
                "&:hover": {
                  backgroundColor: "#FF8C69",
                },
                borderRadius: "20px",
                width: {
                  xs: "90px",
                  sm: "100px",
                  md: "120px",
                },
              }}
              startIcon={<FontAwesomeIcon icon={faUser} />}
            >
              Login
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default OldHeader;
