import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { setProposalData } from "../../../../modules/proposal.slice";
import { useGenerateCKYC } from "../../../../services/CKYC.service";
import {
  useUpdateProposalFormData,
  useGetProposalDetailsQuery,
} from "../../../../services/Proposal.service";
import {
  allowOnlyAlphabets,
  allowOnlyAlphabetsAndNumbers,
  allowOnlyPanCard,
} from "../../../../utils/inputUtils";
import * as yup from "yup";
import Select from "../InputComponents/Select";
import TextInput from "../InputComponents/TextInput";
import YesNo from "../InputComponents/YesNo";
import { Close } from "@mui/icons-material";
import { values } from "lodash";

const occupationOptions = [
  { OPTION_KEY: "1", OPTION_VAL: "Professional" },
  { OPTION_KEY: "2", OPTION_VAL: "Self Employed" },
  { OPTION_KEY: "3", OPTION_VAL: "Retired" },
  { OPTION_KEY: "4", OPTION_VAL: "Housewife" },
  { OPTION_KEY: "5", OPTION_VAL: "Others" },
];

const familyRelationOptions = [
  { OPTION_KEY: "1", OPTION_VAL: "Father" },
  { OPTION_KEY: "2", OPTION_VAL: "Spouse" },
  { OPTION_KEY: "3", OPTION_VAL: "Mother" },
];

const incomeSourceOptions = [
  { OPTION_KEY: "1", OPTION_VAL: "Salary" },
  { OPTION_KEY: "2", OPTION_VAL: "Business" },
  { OPTION_KEY: "3", OPTION_VAL: "Sale of Assets" },
  { OPTION_KEY: "4", OPTION_VAL: "Inheritance" },
];

const addressProofOptions = [
  { OPTION_KEY: "1", OPTION_VAL: "Passport" },
  { OPTION_KEY: "2", OPTION_VAL: "Voter Id" },
  { OPTION_KEY: "3", OPTION_VAL: "Driving License" },
  { OPTION_KEY: "4", OPTION_VAL: "Proof of Possession of Aadhar" },
  { OPTION_KEY: "5", OPTION_VAL: "NREGA Job Card" },
  { OPTION_KEY: "6", OPTION_VAL: "National Population Register" },
];

const documentMap = {
  1: "Passport",
  2: "Voter Id",
  3: "Driving License",
  4: "Proof of Possession of Aadhar",
  5: "NREGA Job Card",
  6: "National Population Register",
  7: "Pan",
  10: "CKYC Identifier",
};

const inlineValidationsMap = {
  1: { maxLength: "8", onInput: (e) => allowOnlyPanCard(e) },
  2: { maxLength: "20", onInput: allowOnlyAlphabetsAndNumbers },
  3: { maxLength: "20", onInput: allowOnlyAlphabetsAndNumbers },
  4: { maxLength: "165" },
  5: { maxLength: "20" },
  6: { maxLength: "20" },
  7: { maxLength: "10" },
  10: { maxLength: "14" },
};

const CKYCStarForm = () => {
  const { generateCKYC, isGeneratingCKYC, generateCkycData, photo } =
    useGenerateCKYC();
  const { saveForm } = useUpdateProposalFormData();
  const dispatch = useDispatch();
  const { activeProposal, proposalData } = useTypedSelector(
    (state) => state.proposal
  );
  const { product, quote_data } = useGetProposalDetailsQuery();

  const proposer_details = proposalData?.proposer_details || {};
  const ckyc_details = proposalData?.ckyc_details || {};

  const initialValues = {
    address_proof: ckyc_details?.document_type || "",
    isAnyonePep: ckyc_details?.isAnyonePep || "N",
    pepRemarks: ckyc_details?.pepRemarks || "",
    incomeSourceId: ckyc_details?.incomeSourceId || "",
    occupation: ckyc_details?.occupation || "",
    familyMemberFirstName: ckyc_details?.familyMemberFirstName || "",
    familyMemberLastName: ckyc_details?.familyMemberLastName || "",
    familyRelationshipId: ckyc_details?.familyRelationshipId || "",
    document_type: ckyc_details?.document_type || "",
    document_number: ckyc_details?.document_number || "",
    proposer_address_proof: ckyc_details?.proposer_address_proo || "",
    proposer_photo: ckyc_details?.proposer_phot || "",
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape({
          isAnyonePep: yup.string(),
          pepRemarks: yup
            .string()
            .nullable()
            .when("isAnyonePep", {
              is: "Y",
              then: yup.string().required("Remarks is required"),
            }),
          incomeSourceId: yup.string().required("Source is required"),
          occupation: yup.string().required("Occupation is required"),
          familyMemberFirstName: yup
            .string()
            .required("Family Member First Name is required"),
          familyMemberLastName: yup
            .string()
            .required("Family Member Last Name is required"),
          familyRelationshipId: yup.string().required("Relation is required"),
          ...((!ckyc_details?.ckycId || ckyc_details?.ckycId) && {
            document_type: yup.string().required("Address Proof is required"),
            document_number: yup.string().required("Proof Id is required"),
            // proposer_address_proof: yup
            //   .mixed()
            //   .required()
            //   .test("proposer_address_proof", "Max Size Limit is 5MB", (val) =>{if (typeof val === "string")
            //     return true
            //     return val && val.size / 1024 / 1024 <= 5}),
            // proposer_photo: yup
            //   .mixed()
            //   .required()
            //   .test("proposer_photo", "Max Size Limit is 5MB", (val) =>{if (typeof val === "string")
            //     return true
            //     return val && val.size / 1024 / 1024 <= 5}),
            proposer_address_proof: yup
              .mixed()
              .required()
              .test(
                "proposer_address_proof",
                "Max Size Limit is 5MB",
                (val) => {
                  if (typeof val === "string") return true;
                  return val && val.size / 1024 / 1024 <= 5;
                }
              )
              .test("fileType", "PNG files are not supported", (val) => {
                if (typeof val === "string") return true;
                return val && val.type !== "image/png";
              }),

            proposer_photo: yup
              .mixed()
              .required()
              .test("proposer_photo", "Max Size Limit is 5MB", (val) => {
                if (typeof val === "string") return true;
                return val && val.size / 1024 / 1024 <= 5;
              })
              .test("fileType", "PNG files are not supported", (val) => {
                if (typeof val === "string") return true;
                return val && val.type !== "image/png";
              }),
          }),
        })}
        onSubmit={async (values, { setFieldValue }) => {
          const ckyc = await generateCKYC({
            first_name: proposer_details.first_name,
            last_name: proposer_details.last_name,
            gender: proposer_details.gender,
            dob: proposer_details.dob,
            pan_no: proposer_details.pan_no,
            pincode: proposer_details.pincode,
            address_line_1: proposer_details.address_line_1,
            address_line_2: proposer_details.address_line_2,
            cityName: proposer_details.city,
            ...values,
          });

          saveForm({
            ckyc_details: {
              ckycReferenceId: ckyc.ckycReferenceId,
              proposer_address_proo: ckyc.proposer_address_proof,
              proposer_phot: ckyc.proposer_photo,
              ...values,
            },
            from_screen: "ckyc",
          });

          dispatch(
            setProposalData({
              ckyc_details: {
                ckycReferenceId: ckyc.ckycReferenceId,
                proposer_address_proo: ckyc.proposer_address_proof,
                proposer_phot: ckyc.proposer_photo,
                ...values,
              },
              from_screen: "ckyc",
            })
          );
        }}
      >
        {({ handleChange, handleBlur, setFieldValue, values, submitForm }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <YesNo
                  label="Are you a PEP - Politically Exposed Person or Any insured members is PEP or related to PEP?"
                  name="isAnyonePep"
                />
              </Grid>
              {values.isAnyonePep === "Y" && (
                <>
                  <Grid item md={6} xs={12}>
                    <TextInput label="Remarks" name="pepRemarks" />
                  </Grid>
                </>
              )}
              <Grid item md={6} xs={12}>
                <Select
                  name="incomeSourceId"
                  options={incomeSourceOptions}
                  label="Source of Income"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Select
                  name="occupation"
                  options={occupationOptions}
                  label="Occupation"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextInput
                  name="familyMemberFirstName"
                  label="Family Member First Name"
                  onInput={allowOnlyAlphabets}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextInput
                  name="familyMemberLastName"
                  label="Family Member Last Name"
                  onInput={allowOnlyAlphabets}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Select
                  name="familyRelationshipId"
                  options={familyRelationOptions}
                  label="Relation with Family Member"
                />
              </Grid>
              {!ckyc_details?.ckycId && (
                <>
                  <Grid item md={6} xs={12}>
                    <Select
                      name="document_type"
                      options={addressProofOptions}
                      label="Address Proof"
                    />
                  </Grid>
                  {values.document_type && (
                    <Grid item md={6} xs={12}>
                      <TextInput
                        name="document_number"
                        key={documentMap[values.document_type]}
                        label={documentMap[values.document_type] || ""}
                        {...inlineValidationsMap[values.document_type]}
                      />
                    </Grid>
                  )}
                  <Grid item md={6} xs={12}>
                    {values.proposer_address_proof ? (
                      <Box display="flex" flexDirection="column">
                        <Typography
                          fontSize={{ xs: "15px", sm: "16px" }}
                          fontWeight="500"
                          marginBottom="8px"
                        >
                          View Uploaded Address Proof
                        </Typography>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          padding="1px"
                          border="1px solid #ccc"
                          borderRadius="4px"
                        >
                          <Box
                            flexGrow={1}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            padding="4px"
                          >
                            <a
                              target="_blank"
                              href={
                                values.proposer_address_proof instanceof File
                                  ? URL.createObjectURL(
                                      values.proposer_address_proof
                                    )
                                  : values.proposer_address_proof
                              }
                              rel="noopener noreferrer"
                            >
                              View Uploaded Address Proof
                            </a>
                          </Box>
                          <Tooltip title="Clear">
                            <IconButton
                              color="primary"
                              onClick={() => {
                                setFieldValue("proposer_address_proof", null);
                              }}
                              aria-label="clear"
                            >
                              <Close />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    ) : (
                      <TextInput
                        type="file"
                        name="proposer_address_proof"
                        label="Upload document"
                        onChange={(event) =>
                          setFieldValue(
                            "proposer_address_proof",
                            event.target.files[0]
                          )
                        }
                      />
                    )}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {values.proposer_photo ? (
                      <Box display="flex" flexDirection="column">
                        <Typography
                          fontSize={{ xs: "15px", sm: "16px" }}
                          fontWeight="500"
                          marginBottom="8px"
                        >
                          View Uploaded Photo
                        </Typography>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          padding="1px"
                          border="1px solid #ccc"
                          borderRadius="4px"
                        >
                          <Box
                            flexGrow={1}
                            display="flex"
                            alignItems="center"
                            padding="4px"
                            justifyContent="center"
                          >
                            <a
                              href={
                                values.proposer_photo instanceof File
                                  ? URL.createObjectURL(values.proposer_photo)
                                  : values.proposer_photo
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Uploaded Photo
                            </a>
                          </Box>
                          <Tooltip title="Clear">
                            <IconButton
                              color="primary"
                              onClick={() => {
                                setFieldValue("proposer_photo", null);
                              }}
                              aria-label="clear"
                            >
                              <Close />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    ) : (
                      <TextInput
                        type="file"
                        name="proposer_photo"
                        label="Upload photo"
                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          if (file) {
                            setFieldValue("proposer_photo", file);
                          }
                        }}
                      />
                    )}
                  </Grid>
                </>
              )}
              <Grid
                item
                xs={12}
                justifyContent="center"
                display={"flex"}
                my={1}
              >
                <Box sx={{ position: "relative" }}>
                  <Button
                    size="large"
                    variant="contained"
                    disabled={isGeneratingCKYC}
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    Proceed to Insurer Details
                  </Button>
                  {isGeneratingCKYC && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CKYCStarForm;
